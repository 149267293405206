<template>
  <v-card class="mt-4">
    <v-card-title v-if="dataRanges">
      <span>{{$t('t.AgingBuckets')}}</span>
    </v-card-title>
    <v-card-text>
      <div>
        <div class="d-flex">
          <v-btn
            v-if="!readonly"
            fab
            x-small
            color="secondary"
            :disabled="dataRanges.length <= 2"
            @click="removeBucket"
            class="mr-4"
          >
            <v-icon>{{$icon('i.Minus')}}</v-icon>
          </v-btn>
          <span class="title">{{dataRanges.length}}</span>
          <v-btn
            v-if="!readonly"
            fab
            x-small
            color="secondary"
            @click="addBucket"
            :disabled="dataRanges.length >= 15"
            class="ml-4"
          >
            <v-icon>{{$icon('i.Plus')}}</v-icon>
          </v-btn>
          <number-field
            :dense="true"
            :disabled="disabled"
            :readonly="readonly"
            :label="$t('t.StartAgingBucket')"
            :number="dataRanges[0]"
            :rules="startBucketRules"
            @update:number="setNumber(0, $event)"
            class="ml-4"
          />
        </div>
      </div>
      <grid
        class="flex-grow-1"
        item-min-width="250px"
      >
        <div
          v-for="(bucket, index) in dataRanges.slice(1)"
          :key="index"
          class="d-flex ml-8"
        >

          <div class="full-width">
            <span class="subtitle-2"></span><span class="title">{{computedAgingDisplay[index]}}</span>
            <number-field
              :dense="dense"
              :disabled="disabled"
              :readonly="readonly"
              :label="$t('t.BucketNumberOfDays')"
              :number="dataRanges[index + 1]"
              @update:number="setNumber(index + 1, $event)"
              :rules="bucketRules"
            />
            <translations
              :is-new="translatedValuesIsNew[index]"
              :label="$t('t.Name')"
              :translations="dataRangeTranslatedValues[index]"
              :rules="translationLengthRules"
              :counter="50"
              @update:translations="setTranslation(index, $event)"
            />
          </div>
        </div>
        <div class="full-width ml-8">
          <div>
            <span class="subtitle-2"></span><span class="title">{{computedAgingDisplay[dataRanges.length - 1]}}</span>
            <v-text-field
              :dense="dense"
              :disabled="true"
              :label="$t('t.BucketNumberOfDays')"
              value="+∞"
            />
            <translations
              :is-new="translatedValuesIsNew[dataRangeTranslatedValues.length - 1]"
              :label="$t('t.Name')"
              :translations="dataRangeTranslatedValues[dataRangeTranslatedValues.length - 1]"
              :rules="translationLengthRules"
              :counter="50"
              @update:translations="setTranslation(dataRangeTranslatedValues.length - 1, $event)"
            />
          </div>
        </div>
      </grid>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    Grid: () => import('@/components/grid'),
    NumberField: () => import('@/components/number-field'),
    Translations: () => import('@/components/translations')
  },
  computed: {
    computedAgingDisplay () {
      const display = []
      if (this.dataRanges.length) {
        let i = 1
        let from = this.dataRanges[0]
        while (i < this.dataRanges.length) {
          const to = from - 1 + this.dataRanges[i]
          display.push(from + ' \u{21E2} ' + to)
          from = to + 1
          i++
        }
        display.push(from + ' \u{21E2} ' + '∞')
      }
      return display
    }
  },
  data () {
    return {
      bucketRules: [v => (v >= 0 && v <= 999) || this.$t('t.InvalidValueRange', { min: 0, max: 999 })],
      dataRanges: [],
      dataRangeTranslatedValues: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      startBucketRules: [v => (v >= -999 && v <= 999) || this.$t('t.InvalidValueRange', { min: -999, max: 999 })],
      translationLengthRules: [v => v?.length <= 50],
      translatedValuesIsNew: []
    }
  },
  methods: {
    addBucket () {
      const v = this.dataRanges.length ? this.dataRanges[this.dataRanges.length - 1] : 1
      this.dataRanges.push(v)
      this.dataRangeTranslatedValues.push(null)
      this.dataDocumentEmit()
    },
    removeBucket () {
      this.dataRanges.pop()
      this.dataRangeTranslatedValues.pop()
      this.dataDocumentEmit()
    },
    setNumber (idx, value) {
      this.dataRanges[idx] = this.lodash.cloneDeep(value)
      this.dataDocumentEmit()
    },
    setTranslation (idx, value) {
      this.dataRangeTranslatedValues[idx] = value[0]?.length === 0 ? null : this.lodash.cloneDeep(value)
      this.dataDocumentEmit()
    },
    emitDocument () {
      if (!this.lodash.isEqual(this.ranges, this.dataRanges)) {
        this.$emit('update:ranges', this.lodash.cloneDeep(this.dataRanges))
      }
      if (!this.lodash.isEqual(this.rangeTranslatedValues, this.dataRangeTranslatedValues)) {
        this.$emit('update:rangeTranslatedValues', this.lodash.cloneDeep(this.dataRangeTranslatedValues))
      }
    }
  },
  props: {
    ranges: Array,
    rangeTranslatedValues: Array,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean,
    isPristine: Boolean
  },
  watch: {
    ranges: {
      immediate: true,
      handler (v) {
        this.dataRanges = v?.length ? this.lodash.cloneDeep(v) : [1, 30]
        this.dataDocumentEmit()
      }
    },
    rangeTranslatedValues: {
      immediate: true,
      handler (v) {
        this.dataRangeTranslatedValues = v?.length ? this.lodash.cloneDeep(v) : [null, null]
        if (this.isPristine) {
          this.translatedValuesIsNew = this.dataRangeTranslatedValues.map(p => !p)
        }
        this.dataDocumentEmit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width calc(100% - 20px)
</style>
